import request from '@/utils/request'
import tokenRequest from '@/utils/tokenRequest'
import requestres from '@/utils/requestres'

import { IUserState, PhoneLogin, Register } from '@/store/interface/user'
import { 
  BasicsTab, 
  PostTab, 
  hrUserFormState, 
  AddAcademic, 
  WorkHistory, 
  Qualification, 
  AcademicTitle, 
  HrUserQdApply,
  QdApplyHrUser,
  CompleteHandler,
  OtherCertificates
} from '@/views/HrUser/data'

enum Api {
  massTexting = '/testcenter-server/send/message/all?content=',
  GET_PhoneLogin = '/sfsj-server/sys/phoneLogin',
  checkOnlyUser = '/sfsj-server/sys/user/checkOnlyUser',
  checkOnlyemail = '/hr-server/hr/employee/entryApplication/checkEmail',//修改邮箱唯一效验接口
  
  refreshToken = '/sfsj-server/sys/user/refreshToken',
  UserRegister = '/sfsj-server/sys/user/register',
  GET_logout = '/sfsj-server/sys/logout',
  GET_CODE = '/sfsj-server/sys/sms',
  getJhDictItems = '/sfsj-server/sys/dict/getDictItem',
  // /sfsj-server/sys/api/getJhDictItems 
  generateCode = '/hr-server/hr/employee/entryApplication/generateCode',
  parseIDCard = '/hr-server/hr/employee/entryApplication/parseIDCard',
  ADD_USER = '/hr-server/hr/employee/entryApplication/add',
  EDIT_USER = '/hr-server/hr/employee/entryApplication/edit',
  getByPhone = '/hr-server/hr/employee/entryApplication/getByPhone',
  queryById = '/hr-server/hr/employee/entryApplication/queryById',
  parseBankCard = '/hr-server/hr/employee/entryApplication/parseBankCard',
  parseGraduate = '/hr-server/hr/employee/entryApplication/parseGraduate',
  uploadRequest = '/sfsj-server/sys/oss/api/uploadRequest',

  addAcademic = '/hr-server/hr/employee/entryApplicationAcademicDegree/add',
  listAcademic = '/hr-server/hr/employee/entryApplicationAcademicDegree/list',
  deleteAcademic = '/hr-server/hr/employee/entryApplicationAcademicDegree/delete',
  editAcademic = '/hr-server/hr/employee/entryApplicationAcademicDegree/edit',

  addWorkHistory = '/hr-server/hr/employee/entryApplicationWorkHistory/add',
  listWorkHistory = '/hr-server/hr/employee/entryApplicationWorkHistory/list',
  deleteWorkHistory = '/hr-server/hr/employee/entryApplicationWorkHistory/delete',
  editWorkHistory = '/hr-server/hr/employee/entryApplicationWorkHistory/edit',

  addQualification = '/hr-server/hr/employee/entryApplicationQualification/add',
  addOtherCertificates ='/hr-server/hrEmployeeOtherAttachment/add',
  deitOtherCertificates='/hr-server/hrEmployeeOtherAttachment/edit',
  listQualification = '/hr-server/hr/employee/entryApplicationQualification/list',
  deleteQualification = '/hr-server/hr/employee/entryApplicationQualification/delete',
  deleteOtherCertificates= '/hr-server/hrEmployeeOtherAttachment/delete',
  editQualification = '/hr-server/hr/employee/entryApplicationQualification/edit',

  addAcademicTitle = '/hr-server/hr/employee/entryApplicationAcademicTitle/add',
  listAcademicTitle = '/hr-server/hr/employee/entryApplicationAcademicTitle/list',
  listOtherCertificates='/hr-server/hrEmployeeOtherAttachment/list',
  deleteAcademicTitle = '/hr-server/hr/employee/entryApplicationAcademicTitle/delete',
  editAcademicTitle = '/hr-server/hr/employee/entryApplicationAcademicTitle/edit',
  
  saveAndStartAct = '/hr-server/hr/employee/entryApplication/saveAndStartAct',
  getActStartProcess = '/activiti-server/activiti/definition/process/',
  qdApply = '/activiti-server/activiti/definition/apply',
  getProcessByActi = '/activiti-server/activiti/definition/find/',
  getFormKey = '/activiti-server/activiti/definition/get/form',
  getTaskList = '/activiti-server/activiti/process/taskList',
  listHistory = '/activiti-server/activiti/process/listHistory',

  secret = '/activiti-server/h5/filter/secret',
  getTaskId = '/activiti-server/h5/filter/process/',
  completeHandler = '/activiti-server/activiti/process/complete',

  getMajorList="/hr-server/hrTitleMajorMapping/makeMajorTree",
  getPracticingList="/hr-server/hrCertificateProjectMapping/makeCertificateTreeFilter",
  getSpecialWorkTree="/hr-server/hrEmployeeOtherAttachment/specialWorkTree",
  getMajorMappingList="/hr-server/hrTitleMajorMapping/makeTitleTree",

  idCardCheck="/loan-server/idCard/idCardCheck"
  
}
// 入职申请-生成二维码
export const generateCode = (params: { 
  userId: string;
}): any => request.get(Api.generateCode, params)
// 获取我的待办列表
export const getTaskList = (params: { 
  businessKey: string | number; 
  definitionId: string | number; 
  pageSize: number; 
  pageNum: number 
}): any => tokenRequest.post(Api.getTaskList, params)
// 获取当前用户名
export const getActiveUser = (params: {id: string }): any => tokenRequest.get('/sfsj-server/sys/api/getUserById', params)
export const getRegUserInfo = (params : {id: string}) : any => tokenRequest.get('/hr-server/branch/company/queryByOrgId', params)
//流程变量
export const updateVariables = (params) : any => tokenRequest.post('/activiti-server/activiti/process/updateVariables', params)


// 获取审批历史
export const listHistory = (params: { processInstanceId: string | number; }): any => tokenRequest.post(Api.listHistory, params)
// 获取是否可以编辑
export const getFormKey = (params: { processDefinitionId: string; taskId: string }): any => tokenRequest.get(Api.getFormKey, params)
// 审批
export const completeHandler = (params: CompleteHandler): any => tokenRequest.post(Api.completeHandler, params)
// 根据instanceId 获取taskid
export const getTaskId = (params: { instanceId: string | number }): any => tokenRequest.get(Api.getTaskId + params.instanceId)
// 获取审批需要的数据（包含表单id等）
export const secret = (params: { id: string | number }): any => tokenRequest.get(Api.secret, params)
// 入职申请-通过id查询
export const queryById = (params: { id: string | number }): any => tokenRequest.get(Api.queryById, params)
// 获取字典
export const getJhDictItems = (params: { code: string }): any => tokenRequest.get(Api.getJhDictItems, params)
// 启动人事入职流程
export const getProcessByActi = (params: { activitiId: string | number }): any => tokenRequest.get(Api.getProcessByActi + params.activitiId)
// 启动人事入职流程
export const qdApply = (params: QdApplyHrUser): any => tokenRequest.post(Api.qdApply, params)
// 获取启动所需参数
export const getActStartProcess = (params: { key: string }): any => tokenRequest.get(Api.getActStartProcess + params.key)
// 保存人事入职流程
export const saveAndStartAct = (params: HrUserQdApply & { stage: number } & hrUserFormState): any => tokenRequest.post(Api.saveAndStartAct, params)

// 入职申请职称信息-编辑
export const editAcademicTitle = (params: AcademicTitle): any => tokenRequest.put(Api.editAcademicTitle, params)
// 入职申请职称信息-通过id删除
export const deleteAcademicTitle = (params: { id: string }): any => tokenRequest.delete(Api.deleteAcademicTitle, params)
// 入职申请职称信息-分页列表查询
export const listAcademicTitle = (params: { pageNo: number; pageSize: number; hrEntryApplicationId: string | number }): any => tokenRequest.get(Api.listAcademicTitle, params)
// 入职申请职称信息-分页列表查询
export const listOtherCertificates = (params: { pageNo: number; pageSize: number; hrEntryApplicationId: string | number }): any => tokenRequest.get(Api.listOtherCertificates, params)
// 入职申请职称信息-添加
export const addAcademicTitle = (params: AcademicTitle): any => tokenRequest.post(Api.addAcademicTitle, params)

// 入职申请执业资格子表-编辑
export const editQualification = (params: Qualification): any => tokenRequest.put(Api.editQualification, params)
// 入职申请执业资格子表-通过id删除
export const deleteQualification = (params: { id: string }): any => tokenRequest.delete(Api.deleteQualification, params)

// 入职申请其他证书子表-通过id删除
export const deleteOtherCertificates = (params: { id: string }): any => tokenRequest.delete(Api.deleteOtherCertificates, params)
// 入职申请执业资格子表-分页列表查询
export const listQualification = (params: { pageNo: number; pageSize: number; hrEntryApplicationId: string | number }): any => tokenRequest.get(Api.listQualification, params)
// 入职申请执业资格子表-添加
export const addQualification = (params: Qualification): any => tokenRequest.post(Api.addQualification, params)
// 入职申请其他证书-添加
export const addOtherCertificates = (params: OtherCertificates): any => tokenRequest.post(Api.addOtherCertificates, params)

// 入职申请其他证书-编辑
export const editOtherCertificates = (params: OtherCertificates): any => tokenRequest.post(Api.deitOtherCertificates, params)

// 入职申请工作经历信息-编辑
export const editWorkHistory = (params: WorkHistory): any => tokenRequest.put(Api.editWorkHistory, params)
// 入职申请工作经历信息-通过id删除
export const deleteWorkHistory = (params: { id: string }): any => tokenRequest.delete(Api.deleteWorkHistory, params)
// 入职申请工作经历信息-分页列表查询
export const listWorkHistory = (params: { pageNo: number; pageSize: number; hrEntryApplicationId: string | number }): any => tokenRequest.get(Api.listWorkHistory, params)
// 入职申请工作经历信息-添加
export const addWorkHistory = (params: WorkHistory): any => tokenRequest.post(Api.addWorkHistory, params)

// 入职申请学历信息-编辑
export const editAcademic = (params: AddAcademic): any => tokenRequest.put(Api.editAcademic, params)
// 入职申请学历信息-通过id删除
export const deleteAcademic = (params: { id: string }): any => tokenRequest.delete(Api.deleteAcademic, params)
// 入职申请学历信息-分页列表查询
export const listAcademic = (params: { pageNo: number; pageSize: number; hrEntryApplicationId: string | number }): any => tokenRequest.get(Api.listAcademic, params)

// 获取用户列表数据
export const getUserList = (params): any => tokenRequest.get('/sfsj-server/sys/user/list', params)

// 入职申请学历信息-添加
export const addAcademic = (params: AddAcademic): any => tokenRequest.post(Api.addAcademic, params)

//分割单列表信息
export const getSplitASingleList = (params): any => tokenRequest.get('/hr-server/bill/list' ,params)
//获取所有分子公司信息
export const getGenerateBillList = (params): any => tokenRequest.post('/hr-server/tShareholderGd/list/all',params)
//获取所有社保或医保或公积金的PDF
export const getinvoiceSplittingBillPdf = (params): any => tokenRequest.get('/hr-server/invoice-splitting-bill-pdf/list',params)
//解析用户上传的PDF
export const getParsingPdf = (params): any => tokenRequest.post('/hr-server/invoice-splitting-bill-pdf/resolve',params)
// 编辑PDF
export const editPDF = (params): any => tokenRequest.post('/hr-server/invoice-splitting-bill-pdf/correct', params)
// 删除PDF
export const deletePDF = (params): any => tokenRequest.delete('/hr-server/invoice-splitting-bill-pdf/delete', params)

//根据PDF生成完整分割单记录
export const setSplittingBillPdf = (params): any => tokenRequest.post('/hr-server/bill/PDFBill',params)

//分割单列表信息
export const getInvoiceList = (params): any => tokenRequest.get('/hr-server/invoice-splitting-bill-receipt/list' ,params)

//解析发票
export const ParsingTheInvoice = (params): any => tokenRequest.post('/hr-server/invoice-splitting-bill-receipt/resolve',params)

//根据发票生成分割单记录
export const GenerateSplitSheetFromInvoice = (params): any => tokenRequest.post('/hr-server/bill/ReceiptsBill',params)

// 编辑PDF
export const editInvoice = (params): any => tokenRequest.post('/hr-server/invoice-splitting-bill-receipt/correct', params)
// 删除PDF
export const deleteInvoice = (params): any => tokenRequest.delete('/hr-server/invoice-splitting-bill-receipt/delete', params)

//生成带签章的分割单
// export const GeneratePartitionSheet = (params): any => tokenRequest.get('/hr-server/bill/generateBill' ,params)

//编辑分割单
export const editfgd = (params): any => tokenRequest.post('/hr-server/bill/correct', params)

//通过ID获取分割单详情
export const getSplitSheetDetails = (params): any => tokenRequest.get('/hr-server/bill/queryById' ,params)


//获取入职信息专业列表

export const getMajorList = (params: any): any => tokenRequest.get(Api.getMajorList, params)
//执业资格名称

export const getPracticingList = (params: any): any => tokenRequest.get(Api.getPracticingList, params)

//其他证书-证书分类下拉树

export const getSpecialWorkTree = (params: any): any => tokenRequest.get(Api.getSpecialWorkTree, params)

//职称专业

export const getMajorMappingList = (params: any): any => tokenRequest.get(Api.getMajorMappingList, params)

//字典数据
export const ajaxGetDictItems = (code, params)=>tokenRequest.getAction(`/sfsj-server/sys/dict/getDictItems/${code}`,params);

//根据入职申请主键id获取注册人员信息
export const gethrRegistrarInfo = (code)=>tokenRequest.getAction(`/hr-server/hrRegistrarInfo/select/${code}`);


// 新增-修改注册人员信息
export const saveOrUpdate = (params): any => tokenRequest.post('/hr-server/hrRegistrarInfo/saveOrUpdate', params)

// 文件上传
export const uploadRequest = (params: any): any => tokenRequest.post(Api.uploadRequest, params)
// 入职申请-使用手机号查询
export const getByPhone = (params: { phone: string }): any => tokenRequest.get(Api.getByPhone, params)
// 添加身份基础信息
export const ADD_USER = (params: { stage: number; mobilePhone: string | number; }): any => tokenRequest.post(Api.ADD_USER, params)
// 修改身份基础信息
export const EDIT_USER = (params: BasicsTab | PostTab | hrUserFormState | { stage: number }): any => tokenRequest.put(Api.EDIT_USER, params)
// 入职申请-解析毕业证
export const parseGraduate = (params: { "graduateImgUrl": string, }): any => tokenRequest.get(Api.parseGraduate, params)
// 入职申请-解析银行卡
export const parseBankCard = (params: { "bankCardImgUrl": string, }): any => tokenRequest.get(Api.parseBankCard, params)
// 解析身份证
export const parseIDCard = (params: {
  "identityCardBack": string, // 身份证反面
  "identityCardFace": string,
  entryApplicationId?: string,
}): any => tokenRequest.post(Api.parseIDCard, params)
// 注册
export const UserRegister = (params: Register): any => request.post(Api.UserRegister, params)
// 刷新token
export const refreshToken = (params: { refreshToken: any }): any => request.get(Api.refreshToken, params)
// 验证手机号
export const checkOnlyUserPhone = (params: { phone: any }): any => request.get(Api.checkOnlyUser, params)
export const checkOnlyUser = (params: { email?: any }): any => request.get(Api.checkOnlyUser, params)
export const checkOnlyemail = (params: { email?: any ,id?:any}): any => tokenRequest.get(Api.checkOnlyemail, params)

// 验证用户名
export const checkOnlyUserName = (params: { username: any }): any => request.get(Api.checkOnlyUser, params)
// 群发
export const massTexting = (params: { content: any }): any => request.post(Api.massTexting + params.content)
// 退出登录
export const GET_logout = (): any => tokenRequest.post(Api.GET_logout)
// 验证码登录
export const GET_PhoneLogin = (params: PhoneLogin): any => request.post(Api.GET_PhoneLogin, params)
// 获取验证码
export const GET_CODE = (params: {
  mobile: any
  smsmode: any // '1': 注册 '0': 登录
}): any => tokenRequest.post(Api.GET_CODE, params)


// 获取是否可以编辑
export const idCardCheck = (params: { idCard: string; name: string }): any => tokenRequest.get(Api.idCardCheck, params)